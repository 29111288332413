/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

// edited by leeas - removed ApiKeysList due to security concern
import { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Message, Icon, Container } from 'semantic-ui-react';

import { isStoreLoading, isStoreReady, isStoreError } from './models/BaseStore';
import AddAuthenticationProvider from './parts/authentication-providers/AddAuthenticationProvider';
import EditAuthenticationProvider from './parts/authentication-providers/EditAuthenticationProvider';
import MainLayout from './parts/MainLayout';
import Authenticate from './parts/Authenticate';
import User from './parts/users/User';
import Accounts from './parts/accounts/Accounts';
import AddUser from './parts/users/AddUser';
import AddIndex from './parts/accounts/AddIndex';
import UserApplication from './parts/UserApplication';
import AuthenticationProvidersList from './parts/authentication-providers/AuthenticationProvidersList';
// import ApiKeysList from './parts/api-keys/ApiKeysList';
import Dashboard from './parts/dashboard/Dashboard';
import StudiesPage from './parts/studies/StudiesPage';
import AmiWizard from './parts/environments/ami-wizard';
import Wizard from './parts/studies/environment-wizard';
import WorkflowTemplatesList from './parts/workflow-templates/WorkflowTemplatesList';
import WorkflowTemplateDraftEditor from './parts/workflow-templates/drafts/edit/WorkflowTemplateDraftEditor';
import WorkflowsList from './parts/workflows/WorkflowsList';
import WorkflowDraftEditor from './parts/workflows/drafts/edit/WorkflowDraftEditor';
import WorkflowDetailPage from './parts/workflows/published/WorkflowDetailPage';
import WorkflowInstanceDetailPage from './parts/workflows/published/WorkflowInstanceDetailPage';
//import AuditPage from './parts/auditlogs/Audit';
import EnvironmentPage from './parts/environments/EnvironmentPage';
import AmisList from './parts/environments/AmisList';
import SettingsPage from './parts/settings/SettingsPage';
import ImportAMI from './parts/environments/ImportAMI';
import EnvironmentDetailPage from './parts/environments/EnvironmentDetailPage';
import AddUserRole from './parts/users/AddUserRole';
import AddAwsAccount from './parts/accounts/AddAwsAccount';
import CreateAwsAccount from './parts/accounts/CreateAwsAccount';
import CreateEnvironment from './parts/environments/CreateEnvironment';
import CreateSecureEnvironment from './parts/environments/CreateSecureEnvironment';
import AddProject from './parts/projects/AddProject';
import { withRouter } from './helpers/routing';
import { branding } from './helpers/settings';
import AccessRequestsList from './parts/access-requests/AccessRequestsList';
import UploadDirRequests from './parts/uploaddir-requests/UploadDirRequests';
import SecureOutputsPage from './parts/secure-outputs/SecureOutputsPage';
import SecureOutputsSelection from './parts/secure-outputs/SecureOutputsSelection';
import NotebooksPage from './parts/notebooks/NotebooksPage';
import PublishRequestsList from './parts/publish-requests/PublishRequestsList';
// Import Insertion Point (do not change this text, it is being used by hygen cli)

// expected props
// - app (via injection)
// - location (from react router)
// edit 2359 - add support for EMR-hail Notebooks
class AppContainer extends Component {
  componentDidMount() {
    document.title = branding.page.title;
  }

  // edited by 2359 - added routes for AMI
  renderApp() {
    // See https://reacttraining.com/react-router/web/api/withRouter
    const { location, userStore } = this.props;
    const isRootUser = userStore.user.isRootUser;
    const isAdminUser = userStore.user.isAdmin;
    const defaultLocation = {
      pathname: isRootUser ? '/users' : '/dashboard',
      search: location.search, // we want to keep any query parameters
      hash: location.hash,
      state: location.state,
    };

    return (
      <MainLayout>
        <Routes>
          <Route exact path="/" element={<Navigate to={defaultLocation} />} />
          {isAdminUser && <Route path="/authentication-providers/add" element={<AddAuthenticationProvider/>} />}
          {isAdminUser && (
            <Route
              path="/authentication-providers/:authenticationProviderConfigId/edit"
              element={<EditAuthenticationProvider/>}
            />
          )}
          {isAdminUser && <Route path="/authentication-providers" element={<AuthenticationProvidersList/>} />}
          {/* <Route path="/api-keys" component={ApiKeysList} /> */}
          {isAdminUser && <Route path="/users/add" element={<AddUser/>} />}
          {isAdminUser && <Route path="/indexes/add" element={<AddIndex/>} />}
          {isAdminUser && <Route path="/user-roles/add" element={<AddUserRole/>} />}
          {isAdminUser && <Route path="/aws-accounts/add" element={<AddAwsAccount/>} />}
          {isAdminUser && <Route path="/aws-accounts/create" element={<CreateAwsAccount/>} />}
          {isAdminUser && <Route path="/users" element={<User/>} />}
          {isAdminUser && <Route path="/accounts" element={<Accounts/>} />}
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/amis/wizard" element={<AmiWizard/>} />
          {isAdminUser && <Route path="/settings" element={<SettingsPage/>} />}
          <Route path="/studies/wizard" element={<Wizard/>} />
          <Route path="/studies" element={<StudiesPage />} />
          {isAdminUser && (
            <Route path="/workflow-templates/drafts/edit/:draftId" element={<WorkflowTemplateDraftEditor/>} />
          )}
          {isAdminUser && <Route path="/workflow-templates" element={<WorkflowTemplatesList/>} />}
          {isAdminUser && <Route path="/workflows/drafts/edit/:draftId" element={<WorkflowDraftEditor/>} />}
          {isAdminUser && (
            <Route
              path="/workflows/published/id/:workflowId/v/:version/instances/id/:instanceId"
              element={<WorkflowInstanceDetailPage/>}
            />
          )}
          {isAdminUser && <Route path="/workflows/published/id/:workflowId/v/:version" element={<WorkflowDetailPage/>} />}
          {isAdminUser && <Route path="/workflows" element={<WorkflowsList/>} />}
          <Route path="/workspaces/create/:envtype" element={<CreateEnvironment/>} />
          <Route path="/workspaces/secure/create/:envtype" element={<CreateSecureEnvironment/>} />
          <Route path="/workspaces/id/:instanceId" element={<EnvironmentDetailPage/>} />
          <Route path="/workspaces/:envtype" element={<EnvironmentPage/>} />
          {isAdminUser && <Route path="/amis/import" element={<ImportAMI/>} />}
          <Route path="/amis" element={<AmisList/>} />
          {/*isAdminUser && <Route path="/auditlogs" element={<AuditPage/>} />*/}
          {isAdminUser && <Route path="/projects/add" element={<AddProject/>} />}
          <Route path="/access-requests" element={<AccessRequestsList/>} />
          <Route path="/upload-access" element={<UploadDirRequests/>} />
          <Route path="/secure-outputs" element={<SecureOutputsPage/>} />
          <Route path="/secure-vetting" element={<SecureOutputsSelection/>} />
          <Route path="/notebooks" element={<NotebooksPage/>} />
          {isAdminUser && <Route path="/publish-requests" element={<PublishRequestsList/>} />}
          {/* Route Insertion Point (do not change this text, it is being used by hygen cli) */}
        </Routes>
      </MainLayout>
    );
  }

  renderProgress() {
    return (
      <Container text className="pt4">
        <Message icon>
          <Icon name="circle notched" loading />
          <Message.Content>
            <Message.Header>Just one second</Message.Header>
            Great things are now happening, please wait!
          </Message.Content>
        </Message>
      </Container>
    );
  }

  renderWaitRegistration() {
    return <UserApplication />;
  }

  render() {
    console.log("App:: I'm in here!!");
    const { app } = this.props;
    let content = null;

    if (isStoreError(app.startup)) {
      content = <Authenticate />;
    } else if (isStoreLoading(app.startup)) {
      content = this.renderProgress();
    } else if (isStoreReady(app.startup) && app.userAuthenticated && !app.userRegistered) {
      content = this.renderWaitRegistration();
    } else if (isStoreReady(app.startup) && app.userAuthenticated && app.userRegistered) {
      content = this.renderApp();
    } else {
      content = <Authenticate />;
    }

    return content;
  }
}

export default inject('userStore', 'app')(withRouter(observer(AppContainer)));
